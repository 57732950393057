import React from 'react'

import { Landing, SEO } from '../../components/common'
import { Heading, Grid, CallToAction, Paragraph, SubHeading, MutedText, SideHighlight } from '../../pageStyles'


// Styles
import '../../styles/app.css'

const styles = {
    bottomContainer: {
        display: "flex",
        flexDirection: "row",
        maxWidth: "1250px",
        margin: "0.5rem auto 3rem auto",
        padding: "0 4vw",
    },
    headerContainer: {
        display: "flex",
        flexDirection: "row",
        maxWidth: "1250px",
        margin: "3rem auto 0px auto",
        padding: "0 5.5vw",
    },
    hint: {
        margin: "-0.5rem 1rem"
    }
}

const Index = ({ data, location, pageContext }) => {
    return (
        <>
            <Landing>
                <SEO
                    title="Mappa FPV Italia"
                    description="La mappa dei piloti di droni FPV italiani creata da NorthFPV. Scopri se hai qualche pilota vicino a te per volare insieme."
                />
                <div className="container mt-2">
                    <Heading>Mappa FPV Italia by North FPV</Heading>
                    <Paragraph>Questa mappa nasce con lo scopo di geolocalizzare e dare la possibilità a tutti i piloti della nostra Community di incontrarsi, conoscere quali piloti, quali campi di volo e quali team siano presenti nella propria zona.</Paragraph>
                    <Paragraph>La nostra mappa FPV italia è pensata per aiutare i membri della community di North FPV e tutti i piloti di drone racing italiani che si registrano ad incontrarsi più facilmente e individuare più facilmente campi di volo e circuti drone racing fpv italiani nella propria zona.</Paragraph>
                    <SideHighlight>
                        <MutedText>Attenzione la mappa viene aggiornata 1 volta a settimana, se non vedete il vostro nome subito non preoccupatevi e non reinserite i dati</MutedText>
                    </SideHighlight>
                    <SubHeading>Registrati anche tu</SubHeading>
                    <Grid>
                        <CallToAction href="https://docs.google.com/forms/d/e/1FAIpQLSckpl2qZTdndTGnSIKNz9U8aPRr2HdNh_SgAN2DlEaYvUkVJA/viewform">Come Pilota</CallToAction>
                        <CallToAction href="https://docs.google.com/forms/d/e/1FAIpQLSecP8IqmLbjYJlIY4my-MVlFDLoSC9NlCIe4gW27aTHeahZyg/viewform">Come Team</CallToAction>
                        <CallToAction href="https://docs.google.com/forms/d/1RboUmHHM4TrQFi-Bcs-_FeitJMt52qVFQmobc6X7CCs/edit ">Come Campo Volo</CallToAction>
                    </Grid>
                </div>
                <span style={styles.headerContainer}><i className="fas fa-arrow-down"></i><span style={styles.hint}>Apri il menù per scoprire di più</span></span>
                <div style={styles.bottomContainer}>
                    <iframe src="https://www.google.com/maps/d/u/1/embed?mid=1q47e1AB6kHBEShowVQNmYqtj9pCTL7Lm" width="100%" height="800px"></iframe>
                </div>
            </Landing>
        </>
    )
}

export default Index;
